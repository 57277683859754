import * as Stomp from 'stompjs';
import * as SockJS from 'sockjs-client';
import { Injectable } from "@angular/core";
import { WebSocketShareService } from "./websocket.service";
import { environment } from 'src/environments/environment';
import { WebsocketMessage } from '../../models/websocket/websocketMessage';

@Injectable()
export class WebSocketAPI {
    webSocketEndPoint: string = `${environment.baseUrl}/ws`;
    disabled = true;
    stompClient: any;
    
    constructor(private websocketShare: WebSocketShareService){
         
    }

    setConnected(connected: boolean) {
        this.disabled = !connected;
        if (connected) {
        }
      }
    
      connect(topic: string) {
        const socket = new SockJS(this.webSocketEndPoint);
        this.stompClient = Stomp.over(socket);
    
        const _this = this;
        this.stompClient.connect({}, function (frame: any) {
          _this.setConnected(true);
    
          _this.stompClient.subscribe(`/topic/${topic}`, function (message: any) {
            _this.showGreeting(JSON.parse(message.body));
          });
        });
      }
    
      disconnect() {
        if (this.stompClient != null) {
          this.stompClient.disconnect();
        }
    
        this.setConnected(false);
      }
    
      sendName() {
        this.stompClient.send(
          '/app/onboarding',
          {},
          JSON.stringify({ 'name': 'Hola' })
        );
      }
    
      showGreeting(message: any) {
        this.websocketShare.onNewValueReceive(message)

       // this.sendName()

      }

}
